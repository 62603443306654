import { userService } from 'admin/src/service/userService';
import { useRouter } from 'next/router';
import { Form, Input } from 'antd';
import { useState } from 'react';

const Login = () => {
    const router = useRouter();
    const [message, setMessage] = useState('');

    const onFinish = async (values: any) => {
        const { username, password } = values;
        const result = await userService.login(username, password);
        if (result?.token) {
            const returnUrl = router.query.returnUrl as string || '/admin';
            router.push(returnUrl);
        } else {
            setMessage(result);
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div style={{ paddingTop: '100px' }}>
            <div className="form">
                <div className="form-panel">
                    <div className="form-header">
                        <h1>Admin Login</h1>
                    </div>
                    <div className="form-content">
                        <Form
                            name="basic"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                            layout='vertical'
                        >
                            <div className="form-group">
                                <Form.Item
                                    label="Username"
                                    name="username"
                                    rules={[{ required: true, message: 'Please input your username!' }]}
                                >
                                    <Input />
                                </Form.Item>
                            </div>
                            <div className="form-group">
                                <Form.Item
                                    label="Password"
                                    name="password"
                                    rules={[{ required: true, message: 'Please input your password!' }]}
                                >
                                    <Input.Password />
                                </Form.Item>
                            </div>
                            <div className="form-group">
                                <button type="submit">Log In</button>
                            </div>
                            <span className="text-red">{message}</span>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;